<template>
  <v-container fluid>
    <div v-if="isResponsive" class="d-flex">
      <v-col cols="12">
        <div class="display-flex align-items-center">
          <p class="text-title mon-bold">{{ texts.article.textArticle }}</p>
          <v-spacer></v-spacer>
          <v-btn @click="addArticle" class="button-primary mon-bold" elevation="0" icon width="40px !important" height="40px !important">
            <v-icon size="18px">mdi-plus-thick</v-icon>
          </v-btn>
          <v-btn
            @click="dialogUpdatePrices = true"
            :disabled="excelTableData.length == 0"
            depressed
            class="button-add mon-bold ml-2"
            elevation="0"
          >
            {{ texts.article.textButtonUpdatePrice }}
          </v-btn>
        </div>
      </v-col>
    </div>
    <div class="d-flex" v-else>
      <v-col cols="12" md="3">
        <p class="text-title mon-bold">{{ texts.article.textArticle }}</p>
      </v-col>
      <v-col cols="12" md="9" style="display: flex;justify-content: flex-end;">
        <div class="convert-display-block display-flex align-items-center">
          <v-btn @click="addArticle" class="button-add mon-bold ml-2" elevation="0">
            {{ texts.article.textButtonAdd }}
          </v-btn>
        </div>
        <div class="convert-display-block display-flex align-items-center">
          <v-btn
            @click="dialogUpdatePrices = true"
            :disabled="excelTableData.length == 0"
            depressed
            class="button-add mon-bold ml-2"
            elevation="0"
          >
            {{ texts.article.textButtonUpdatePrice }}
          </v-btn>
        </div>
      </v-col>
    </div>
    <v-card class="mx-3 mt-2 mb-8">
      <v-row class="pa-4">
        <v-spacer></v-spacer>
        <v-col sm="3">
          <v-text-field
            v-model="sSearch"
            type="text"
            outlined
            color="#000000"
            dense
            class="global-inputs mon-regular mr-2"
            :placeholder="texts.article.search"
            persistent-placeholder
            clearable
            append-icon="mdi-magnify"
            maxLength="100"
          ></v-text-field>
        </v-col>
        <v-col sm="3">
          <v-select
            v-model="sCategoryIdFilter"
            :label="texts.article.textCatalog"
            outlined
            color="#000000"
            dense
            class="global-inputs mon-regular mr-2"
            :items="aCatalogs"
            item-text="sName"
            item-value="sCategoryId"
            clearable
            @change="returnSubcatalogs"
          ></v-select>
        </v-col>
        <v-col sm="3" style="align-self: center; text-align: right">
          <v-select
            v-model="sSubcategoryIdFilter"
            :label="texts.article.textSubcatalog"
            outlined
            color="#000000"
            dense
            class="global-inputs mon-regular mr-2"
            :items="aSubcatalogs"
            item-text="sSubcategoryName"
            item-value="sSubcategoryId"
            clearable
            @change="returnCatalogs"
          ></v-select>
        </v-col>
        <v-col sm="1" style="align-self: center; text-align: right">
          <article-filter-layout :texts="texts" />
        </v-col>
      </v-row>
      <div v-if="aArticles.length == 0">
        <div
          style="height: 350px !important; margin-bottom: 60px"
          class="
              card-content
              display-flex
              align-items-center
              justify-content-center
            "
        >
          <div>
            <div class="display-flex align-items-center justify-content-center">
              <img class="empty-image" src="@/assets/images/oops.png" alt="" />
            </div>
            <p class="empty-text mon-regular">{{ texts.article.empty }}</p>
          </div>
        </div>
      </div>

      <v-data-table v-else :headers="headers" :disable-sort="true" :items-per-page="iItemsPerPage" :items="aArticles" hide-default-footer>
        <template v-slot:item.aImages="{ item }">
          <img
            :src="item.aImages"
            alt="Product Image"
            style="width: 80px; height: 80px; margin: 5px; border-radius: 15px; margin-bottom: 0px;"
          />
        </template>
        <template v-slot:item.dUnitPrice="{ item }">
          <span> {{ "$" + item.dUnitPrice + " MXN" }} </span>
        </template>
        <template v-slot:item.oMeasurementUnit="{ item }">
          <span> {{ item.oMeasurementUnit.sName }} </span>
        </template>
        <template v-slot:item.dBoxesPerPallet="{ item }">
          <span style="text-wrap: nowrap;"> {{ item.dBoxesPerPallet + " cajas" }} </span>
        </template>
        <template v-slot:item.dPalletsPerTruck="{ item }">
          <span style="text-wrap: nowrap;"> {{ item.dPalletsPerTruck + " pallets" }} </span>
        </template>
        <template v-slot:item.sLocationWarehouse="{ item }">
          <span style="display: flex; align-items: center; font-size: 10px; width: 120px;">
            <v-icon class="d-flex align-start mt-1" color="#9AA6B1" size="18px">
              mdi-google-maps
            </v-icon>
            {{ item.sLocationWarehouse }}
          </span>
        </template>
        <template v-slot:item.bVisible="{ item }">
          <v-chip v-if="item.bVisible" outlined small class="ma-2" color="green">
            Activo
            <!-- <v-icon class="ml-1" style="font-size: 20px;">
                      mdi-play-circle-outline
                    </v-icon> -->
          </v-chip>
          <v-chip v-else class="ma-2" outlined small color="#d8686b">
            Pausado
            <!-- <v-icon style="font-size: 20px;">
                      mdi-pause
                    </v-icon> -->
          </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="articleDetail(item.sProductId)" icon v-bind="attrs" v-on="on">
                <v-icon size="20px" color="#292929">
                  mdi-eye
                </v-icon>
              </v-btn>
            </template>
            <span>Ver Detalle</span>
          </v-tooltip>
        </template>

        <template v-slot:footer>
          <hr />
          <v-pagination color="yellow darken-1" v-model="iCurrentPage" class="mt-2  mon" :length="lengthPage"> </v-pagination>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogUpdatePrices" persistent max-width="1200px" >
      <v-stepper v-model="e1">
        <v-stepper-header style="display: flex; flex-direction: column; flex-wrap: nowrap; height: fit-content;">
          <div style="text-align: end;">
            <v-btn class="pa-0" text @click="cancel()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <div class="d-flex">
            <v-stepper-step @click="e1 = 1" :complete="e1 == 1" step="1">
              Exportar Artículos
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step @click="e1 = 2" :complete="e1 == 2" step="2">
              Importar Artículos
            </v-stepper-step>
          </div>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-container>
              <v-card>
                <v-card-title>
                  Artículos
                  <v-spacer></v-spacer>
                  <div style="display: flex; gap: 20px;">
                    <v-text-field
                      v-model="search"
                      class="pt-0 mt-0"
                      append-icon="mdi-magnify"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                    <v-btn @click="exportToXLSX()" color="green" :disabled="selected.length == 0" class="white--text" elevation="0" small>
                      Descargar <v-icon class="ml-2">mdi-microsoft-excel</v-icon></v-btn
                    >
                  </div>
                </v-card-title>
                <v-data-table
                  fixed-header
                  show-select
                  v-model="selected"
                  item-key="sProductId"
                  :headers="headersExcel"
                  :items="excelTableData"
                  :search="search"
                  :disable-sort="true"
                >
                  <template v-slot:item.dUnitPrice="{ item }">
                    <span> {{ "$" + item.dUnitPrice + " MXN" }} </span>
                  </template>


                  <template v-slot:item.dBoxesPerPallet="{ item }">
                    <span style="text-wrap: nowrap;"> {{ item.dBoxesPerPallet + " cajas" }} </span>
                  </template>
                  <template v-slot:item.dPalletsPerTruck="{ item }">
                    <span style="text-wrap: nowrap;"> {{ item.dPalletsPerTruck + " pallets" }} </span>
                  </template>

                  <template v-slot:item.oMeasurementUnit="{ item }">
                    <span> {{ item.oMeasurementUnit.sName }} </span>
                  </template>
                  <template v-slot:item.bVisible="{ item }">
                    <v-chip v-if="item.bVisible" outlined small class="ma-2" color="green">
                      Activo
                    </v-chip>
                    <v-chip v-else class="ma-2" outlined small color="#d8686b">
                      Pausado
                    </v-chip>
                  </template>
                </v-data-table>
              </v-card>
            </v-container>
          </v-stepper-content>

          <v-stepper-content step="2">
            <div style="display: flex; align-items: flex-end; justify-content: space-between; margin-bottom: 20px;">
              <div style="display: flex; align-items: flex-end;">
                <v-file-input
                  @change="handleFileUpload"
                  v-model="file"
                  hide-details
                  style="min-width: 300px;"
                  multiple
                  label="Subir archivo"
                ></v-file-input>
              </div>
              <v-btn @click="editArticle()" :disabled="results.length == 0" depressed class="gradient-button" color="transparent">
                {{ "Actualizar" }}
              </v-btn>
            </div>
            <v-row v-if="results.length == 0">
              <v-col>
                <div>
                  <div style="height: 350px !important;" class="card-content display-flex align-items-center justify-content-center">
                    <div>
                      <p class="empty-text mon-regular">{{ texts.article.empty }}</p>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
            <div v-else>
              <v-row>
                <v-col v-for="(item, index) in results" :key="index" cols="12" sm="6" md="4" lg="3" xl="2">
                  <v-card class="mx-auto my-3 rounded-lg global-content-card-article">
                    <active-article-component
                      v-if="!item.bVisible"
                      :bVisible="item.bVisible"
                      :inCard="true"
                      class="global-content-active-article"
                    />
                    <div class="content-image pa-4">
                      <img class="rounded-lg" style="width: 100%; height: 100%;" :src="item.img" />
                    </div>
                    <v-card-text
                      style=" margin-bottom:-25px; text-transform: capitalize;"
                      class="f-s14 mon-bold color000000 text-article-name"
                      >{{ item.sName }}</v-card-text
                    >
                    <v-card-text>
                      <div class="f-s10 d-flex mon-SemiBold " style="color: #9AA6B1 !important;">
                        <v-icon class="mr-1" color="#9AA6B1" size="14px">
                          mdi-account
                        </v-icon>
                        {{ item.sVendorEnterpriseName }}
                      </div>
                      <div class="f-s12 d-flex mon-SemiBold text-article-address " style="color: #9AA6B1 !important;">
                        <v-icon class="d-flex align-start mt-1 mr-1" color="#9AA6B1" size="14px">
                          mdi-calendar
                        </v-icon>
                        <span class="d-flex align-start">
                          {{ item.tProductAvailableTime }}
                        </span>
                      </div>
                      <div class="f-s12 d-flex mon-SemiBold text-article-address " style="color: #9AA6B1 !important;">
                        <v-icon class="d-flex align-start mt-1 mr-1" color="#9AA6B1" size="14px">
                          mdi-warehouse
                        </v-icon>
                        <span class="d-flex align-start">
                          {{ item.dInventoryQuantity + " units" }}
                        </span>
                      </div>
                      <div class="f-s12 d-flex mon-SemiBold text-article-address " style="color: #9AA6B1 !important;">
                        <v-icon class="d-flex align-start mt-1 mr-1" color="#9AA6B1" size="14px">
                          mdi-dropbox
                        </v-icon>
                        <span class="d-flex align-start">
                          {{ item.dBoxesPerPallet + " boxes per pallet" }}
                        </span>
                      </div>
                      <div class="f-s12 d-flex mon-SemiBold text-article-address " style="color: #9AA6B1 !important;">
                        <v-icon class="d-flex align-start mt-1 mr-1" color="#9AA6B1" size="14px">
                          mdi-dropbox
                        </v-icon>
                        <span class="d-flex align-start">
                          {{ item.dPalletsPerTruck + " pallets in truck" }}
                        </span>
                      </div>
                      <div class="mon-bold mt-2 text-height text-article-price" style="font-size: 20px;color: #151D27 !important;">
                        <p class="text-article-price">${{ formatMoney(item.dUnitPrice) + " MXN" }}</p>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-dialog>
    <v-overlay style="z-index: 99999999;" :value="overlay">
      <div style="display: flex; flex-direction: column; width: 200px;align-items: center;">
        <v-progress-circular :size="50" color="black" indeterminate></v-progress-circular>
        <v-text class="mt-3">Loading...</v-text>
      </div>
    </v-overlay>
  </v-container>
</template>

<script>
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import moment from "moment-timezone";

export default {
  name: "ArticleLayout",
  props: {
    texts: {
      type: Object,
    },
  },
  data() {
    return {
      //VARIABLES
      search: "",
      aArticles: [],
      aSubcatalogs: [],
      aCatalogs: [],
      sSearch: "",
      sCategoryIdFilter: "",
      sSubcategoryIdFilter: "",
      iItemsPerPage: 12,
      lengthPage: 0,
      iCurrentPage: 1,
      restantHeightName: 0,
      restantHeightLocation: 0,
      restantHeightPrice: 0,
      headers: [
        { text: "Producto", value: "aImages", align: "center" },
        { text: "Nombre", value: "sName" },
        { text: "Precio MXN", value: "dUnitPrice" },
        { text: "Tipo de caja", value: "oMeasurementUnit" },
        { text: "Cajas por pallet", value: "dBoxesPerPallet" },
        { text: "Pallets en flete", value: "dPalletsPerTruck" },
        { text: "Inventario", value: "dInventoryQuantity" },
        { text: "Fecha disponible", value: "tProductAvailableTime" },
        { text: "Ubicación", value: "sLocationWarehouse", align: "center" },
        { text: "Estado", value: "bVisible", align: "center" },
        { text: "Acciones", value: "actions", align: "center" },
      ],
      headersExcel: [
        { text: "Nombre", value: "sName" },
        { text: "Precio MXN", value: "dUnitPrice" },
        { text: "Cajas por pallet", value: "dBoxesPerPallet" },
        { text: "Pallets en flete", value: "dPalletsPerTruck" },
        { text: "Tipo de caja", value: "oMeasurementUnit" },
        { text: "Inventario", value: "dInventoryQuantity" },
        { text: "Fecha disponible", value: "tProductAvailableTime" },
        { text: "Ubicación", value: "sLocationWarehouse" },
        { text: "Estado", value: "bVisible", align: "center" },
      ],
      isResponsive: false,
      dialogUpdatePrices: false,
      e1: 1,
      file: null,
      selected: [],
      excelTableData: [],
      results: [],
      data: [],
      jsonData: null,
      overlay: false,
    };
  },
  beforeMount() {
    this.getArticles();
    this.getCatalogs();
    this.getSubcatalogs();
    this.fillTableExcel();
  },
  updated() {
    this.resizeDivName();
    this.resizeDivLocation();
    this.resizeDivPrice();
  },
  created() {
    window.addEventListener("resize", this.resizeDivName);
    this.resizeDivName();
    window.addEventListener("resize", this.resizeDivLocation);
    this.resizeDivLocation();
    window.addEventListener("resize", this.resizeDivPrice);
    this.resizeDivPrice();
    window.addEventListener("resize", this.handleResize);
    +this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeDivName);
    window.removeEventListener("resize", this.resizeDivLocation);
    window.removeEventListener("resize", this.resizeDivPrice);
    window.addEventListener("resize", this.handleResize);
  },
  methods: {
    addArticle: function() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.get(`${URI}/api/v1/${this.selectLanguage}/vendors/enterprises/${this.$store.state.sEnterpriseId}`, config)
        .then((response) => {
          let arr = response.data.results;
          if (arr.bVerified) {
            DB.get(`${URI}/api/v1/${this.selectLanguage}/vendors/enterprises/${this.$store.state.sEnterpriseId}/warehouses`, {
              headers: {
                Authorization: `Bearer ${this.$store.state.sToken}`,
              },
              params: {},
            })
              .then((response) => {
                let arr = response.data.results;
                if (arr.length > 0) {
                  this.$router.push({
                    name: "NewArticle",
                  });
                  // this.$store.commit("setArticleDialogAdd", true);
                } else {
                  this.mixInfo(this.texts.article.withoutWarehouse);
                }
              })
              .catch((error) => {
                this.mixError(error.response.data.message, error.response.status);
              });
          } else {
            this.mixError(this.texts.article.isVerifiedText);
          }
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    formatMoney: function(money) {
      return FORMAT_MONEY(money);
    },
    articleDetail: function(id) {
      this.$router.push(`/article/${id}`);
    },
    getArticles: function() {
      this.aArticles = [];
      DB.get(`${URI}/api/v1/${this.selectLanguage}/products/vendors`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
        params: {
          iPageNumber: this.iCurrentPage,
          iItemsPerPage: 12,
          sCategoryId: this.sCategoryIdFilter,
          sSubcategoryId: this.sSubcategoryIdFilter,
          sSearch: this.sSearch,
          sAlphOrder: this.sortArticleAlpha,
          sPriceOrder: this.sortArticlePrice,
          dRatingMin: this.sortArticleRating,
        },
      })
        .then((response) => {
          this.aArticles = response.data.results;
          let i = 0;
          this.aArticles = response.data.results.map((e) => {
            i++;
            return {
              ...e,
              aImages: e.aImages.length > 0 ? e.aImages[0].oImages.md : require(`@/assets/images/EmptyImage.jpg`),
            };
          });
          this.lengthPage = response.data.iNumPages;
          this.iCurrentPage = response.data.iCurrentPage;

          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    resizeDivName: function(text) {
      var _text = document.getElementsByClassName("text-article-name");
      let tam = [];
      for (let i = 0; i < _text.length; i++) {
        const element = _text[i];
        tam.push(element.clientHeight);
      }
      let max = Math.max(...tam);
      this.restantHeightName = max;
    },
    resizeDivLocation: function(text) {
      var contentHeight = document.getElementsByClassName("text-article-address");
      let tam = [];
      for (let i = 0; i < contentHeight.length; i++) {
        const element = contentHeight[i];
        tam.push(element.clientHeight);
      }
      let max = Math.max(...tam);
      this.restantHeightLocation = max;
    },
    resizeDivPrice: function() {
      var contentHeight = document.getElementsByClassName("text-article-price");
      let tam = [];
      for (let i = 0; i < contentHeight.length; i++) {
        const element = contentHeight[i];
        tam.push(element.clientHeight);
      }
      let max = Math.max(...tam);
      this.restantHeightPrice = max;
    },
    getCatalogs: function() {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/categories`, {
        headers: {},
        params: {},
      })
        .then((response) => {
          this.aCatalogs = response.data.results;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    returnSubcatalogs: function() {
      this.getSubcatalogs();
    },
    returnCatalogs: function(e) {
      if (e !== null) {
        this.sCategoryIdFilter = this.aSubcatalogs.find((res) => {
          return res.sSubcategoryId == e;
        }).sCategoryId;
      }
    },
    getSubcatalogs: function(e) {
      let res;
      if (this.sCategoryIdFilter == null) {
        res = "";
      } else {
        res = this.sCategoryIdFilter;
      }

      DB.get(`${URI}/api/v1/${this.selectLanguage}/subcategories?sCategoryId=${res}`, {
        headers: {},
        params: {},
      })
        .then((response) => {
          this.aSubcatalogs = response.data.results;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    handleResize: function() {
      if (window.innerWidth < 960) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
    cancel() {
      this.dialogUpdatePrices = false;
      this.results = [];
      this.e1 = 1;
      this.selected = [];
      this.file = null;
    },
    fillTableExcel() {
      this.excelTableData = [];
      DB.get(`${URI}/api/v1/${this.selectLanguage}/products/vendors`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
      })
        .then((response) => {
          this.excelTableData = response.data.results;
          for (let i = 0; i < this.excelTableData.length; i++) {
            let obj = {
              uId: this.excelTableData[i].sProductId ? this.excelTableData[i].sProductId : "",
              Proveedor: this.excelTableData[i].sVendorEnterpriseName ? this.excelTableData[i].sVendorEnterpriseName : "",
              Nombre: this.excelTableData[i].sName ? this.excelTableData[i].sName : "",
              Precio: this.excelTableData[i].dUnitPrices ? this.excelTableData[i].dUnitPrices : "",
              "Cajas por pallet": this.excelTableData[i].dBoxesPerPallet ? this.excelTableData[i].dBoxesPerPallet : "",
              "Tipo de caja": this.excelTableData[i].oMeasurementUnit.sName ? this.excelTableData[i].oMeasurementUnit.sName : "",
              Inventario: this.excelTableData[i].dInventoryQuantity >= 0 ? this.excelTableData[i].dInventoryQuantity : "",
              "Fecha disponible": this.excelTableData[i].tProductAvailableTime ? this.excelTableData[i].tProductAvailableTime : "",
              Estado: this.excelTableData[i].bVisible ? "Activo" : "Inactivo",
            };
            this.data.push(obj);
          }
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    exportToXLSX() {
      if (this.selected.length > 0) {
        let data = this.selected.map((item) => {
          return {
            uId: item.sProductId ? item.sProductId : "",
            Proveedor: item.sVendorEnterpriseName ? item.sVendorEnterpriseName : "",
            Nombre: item.sName ? item.sName : "",
            Precio: item.dUnitPrice ? item.dUnitPrice : "",
            "Cajas por pallet": item.dBoxesPerPallet ? item.dBoxesPerPallet : "",
            "Pallets en flete": item.dPalletsPerTruck ? item.dPalletsPerTruck : "",
            "Tipo de caja": item.oMeasurementUnit.sName ? item.oMeasurementUnit.sName : "",
            Inventario: item.dInventoryQuantity >= 0 ? item.dInventoryQuantity : "",
            "Fecha disponible": item.tProductAvailableTime ? item.tProductAvailableTime : "",
            "Id de almacen": item.sVendorWarehouseId ? item.sVendorWarehouseId : "",
            Estado: item.bVisible ? "Activo" : "Inactivo",
          };
        });

        let nameXLSX = "Lista_Precios_" + moment().format("YYYY-MM-DD") + ".xlsx";
        // Crea una hoja de cálculo y agrega los datos
        const worksheet = XLSX.utils.json_to_sheet(data);
        // Crea un libro de trabajo y agrega la hoja de cálculo
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Hoja1");
        // Convierte el libro de trabajo a un archivo binario
        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
        // Crea un Blob a partir del archivo binario
        const blob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        // Descarga el archivo utilizando FileSaver.js
        saveAs(blob, nameXLSX);
      }
    },
    async handleFileUpload(event) {
      const file = event[0];
      const reader = new FileReader();
      this.jsonData = [];
      if (file) {
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

          // Guarda el JSON en los datos del componente
          this.jsonData = json;
          const headers = [
            "sProductId",
            "sVendorEnterpriseName",
            "sName",
            "dUnitPrice",
            "dBoxesPerPallet",
            "dPalletsPerTruck",
            "bVisible",
            "dInventoryQuantity",
            "tProductAvailableTime",
            "sVendorWarehouseId",
            "bVisible",
          ];
          this.jsonData = this.jsonData.slice(1); // Resto de elementos del JSON contienen los datos

          this.jsonData = this.jsonData.map((row) => {
            const obj = {};
            headers.forEach((header, index) => {
              obj[header] = row[index];
            });
            return obj;
          });

          const idsConDiferencias = [];
          this.excelTableData.forEach((objeto) => {
            const sProductId = objeto.sProductId;
            const objetoSegundoArray = this.jsonData.find((item) => item.sProductId === sProductId);
            if (objetoSegundoArray) {
              const tieneDiferencias =
                objeto.dUnitPrice !== objetoSegundoArray.dUnitPrice ||
                objeto.dInventoryQuantity !== objetoSegundoArray.dInventoryQuantity ||
                objeto.tProductAvailableTime !== objetoSegundoArray.tProductAvailableTime ||
                objeto.dBoxesPerPallet !== objetoSegundoArray.dBoxesPerPallet ||
                objeto.dPalletsPerTruck !== objetoSegundoArray.dPalletsPerTruck
              if (tieneDiferencias) {
                objetoSegundoArray.img =
                  objeto.aImages.length > 0 ? objeto.aImages[0].oImages.md : require(`@/assets/images/EmptyImage.jpg`);
                idsConDiferencias.push(objetoSegundoArray);
              }
            }
          });
          this.results = idsConDiferencias;
        };
        reader.readAsArrayBuffer(file);
      } else {
        this.results = [];
      }
    },
    editArticle() {
      this.overlay = true;
      const params = this.results.map((obj) => {
        const { sProductId, dUnitPrice, dInventoryQuantity, tProductAvailableTime, sVendorWarehouseId, dBoxesPerPallet, dPalletsPerTruck } = obj;
        const fechaConvertida = moment(tProductAvailableTime, "DD-MM-YYYY hh:mma").format("YYYY-MM-DD HH:mm:ss.SSS ZZ");
        const sEnterpriseId = this.$store.state.sEnterpriseId;
        return { sProductId, dUnitPrice, dInventoryQuantity, tProductAvailableTime: fechaConvertida, sVendorWarehouseId, sEnterpriseId, dBoxesPerPallet, dPalletsPerTruck };
      });
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.patch(`${URI}/api/v1/${this.selectLanguage}/products/update/prices`, params, config)
        .then((response) => {
          this.bLoading = false;
          this.mixSuccess(response.data.message);
          this.cancel();
          this.getArticles();
          this.fillTableExcel();
          this.$store.commit("refresher", true);
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        })
        .finally(() => {
          this.overlay = false;
        });
    },
  },
  computed: {
    selectLanguage: function() {
      return this.$store.state.language;
    },
    refresh: function() {
      return this.$store.state.refresh;
    },
    sFilter: function() {
      return this.$store.state.sFilter;
    },
    sortArticlePrice: function() {
      return this.$store.state.sortArticlePrice;
    },
    sortArticleAlpha: function() {
      return this.$store.state.sortArticleAlpha;
    },
    sortArticleRating: function() {
      return this.$store.state.sortArticleRating;
    },
  },
  watch: {
    selectLanguage: function() {
      if (this.selectLanguage) {
        this.getArticles();
        this.fillTableExcel();
        this.getCatalogs();
        this.getSubcatalogs();
      }
    },
    sCategoryIdFilter: function() {
      this.iCurrentPage = 1;
      this.getArticles();
      this.sCategoryIdFilter == null
        ? this.$store.commit("setCategoryIdFilter", "")
        : this.$store.commit("setCategoryIdFilter", this.sCategoryIdFilter);
    },
    sSubcategoryIdFilter: function() {
      this.iCurrentPage = 1;
      this.getArticles();
      this.sSubcategoryIdFilter == null
        ? this.$store.commit("setSubcategoryIdFilter", "")
        : this.$store.commit("setSubcategoryIdFilter", this.sSubcategoryIdFilter);
    },
    sSearch: function() {
      this.iCurrentPage = 1;
      this.getArticles();
      lodash.debounce(function(val) {
        this.$store.commit("setSearch", this.sSearch);
      }, 300);
    },
    iCurrentPage: function() {
      this.getArticles();
    },
    refresh: function() {
      this.getArticles();
    },
    sortArticlePrice: function() {
      this.iCurrentPage = 1;
      this.getArticles();
    },
    sortArticleAlpha: function() {
      this.iCurrentPage = 1;
      this.getArticles();
    },
    sortArticleRating: function() {
      this.iCurrentPage = 1;
      this.getArticles();
    },
  },
};
</script>

<style scoped>
.card-content {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  opacity: 1;
  padding: 10px;
}

.disponibility-article {
  background-color: #f50017;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  width: 40%;
  padding: 8px;
}

.disponibility-article p {
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
  margin-bottom: 0px;
}

.text-calif {
  text-align: right;
  font-size: 12px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}

.text-calif-num {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #ffc92a;
  opacity: 1;
  margin-bottom: 0px;
}

.text-article-name {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}

.text-article-address {
  text-align: left;
  font-size: 10px;
  letter-spacing: 0px;
  color: #9aa6b1;
  opacity: 1;
  margin-bottom: 0px;
}

.button-view {
  width: 100%;
  height: 35px !important;
  background: transparent linear-gradient(260deg, #ffc556 0%, #fffc81 100%) 0% 0% no-repeat padding-box;
  border-radius: 13px;
  text-align: center;
  font-size: 12px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  text-transform: initial;
}

.text-article-price {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}

.text-calification-number {
  text-align: left;
  font-size: 10px;
  letter-spacing: 0px;
  color: #ffc92a;
  opacity: 1;
  margin-bottom: 0px;
}

.text-calification-texts {
  text-align: left;
  font-size: 10px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}

.card-container {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  padding: 15px;
  position: relative;
}

.text-address {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.3;
  margin-bottom: 5px;
}

.text-phone {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.3;
  margin-bottom: 5px;
}

.button-view-detail {
  background-color: #ffffff !important;
  border: 1px solid #283c4d;
  border-radius: 13px;
  opacity: 1;
  height: 45px;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  text-transform: initial;
}

.text-link {
  text-align: right;
  font-size: 16px;
  letter-spacing: 0px;
  color: #0071ff;
  opacity: 1;
  margin-bottom: 0px;
}

.text-underline:hover {
  text-decoration: underline;
}

.scroll-horizontal {
  width: 100%;
  overflow: auto;
}

.content-image {
  width: 100%;
  height: 250px;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: contain) {
  .content-image img {
    height: 100%;
    object-fit: contain;
    object-position: center center;
    border-radius: 10px;
  }
}

.text-title {
  text-align: left;
  font-size: 21px;
  letter-spacing: 0px;
  color: #3d414a;
  opacity: 1;
}

.button-add {
  height: 40px !important;
  background: transparent linear-gradient(258deg, #ffc556 0%, #fffc81 100%) 0% 0% no-repeat padding-box;
  border-radius: 13px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  text-transform: initial;
}

.text-title {
  text-align: left;
  font-size: 30px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}
.gradient-button {
  background: transparent linear-gradient(228deg, #ffc556 0%, #fffc81 100%) 0% 0% no-repeat padding-box;
}

@media (max-width: 600px) {
  .convert-display-block {
    display: block;
  }
}
</style>
